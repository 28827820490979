import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { AddBox } from "./components/AddBox";
import React from "react";
import ExpressQuestionCard from "./components/ExpressQuestionCard";
import Update from "./components/Update";
import ErrorDiv from "./components/ErrorDiv";
import MongodbQuestionCard from "./components/MongodbQuestionCard";
import ErrorBoundary from "./components/ErrorBoundary";
import { AddBoxTwo } from "./components/AddBoxTwo";
const ReactQuestionCard = lazy(() => import("./components/ReactQuestionCard"));
const JavascriptQuestionCard = lazy(() =>
  import("./components/JavascriptQuestionCard")
);

function App() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [below900, setBelow900] = useState(false);
  const [page, setPage] = useState("React");
  const changePage = useCallback(
    (value) => {
      setPage(value);
    },
    [page]
  );

  const updateScreenWidth = () => {
    if (window.innerWidth < 900) {
      setBelow900(true);
    } else {
      setBelow900(false);
    }
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    updateScreenWidth();
    window.addEventListener("resize", updateScreenWidth);
    return () => {
      window.removeEventListener("resize", updateScreenWidth);
    };
  }, [screenWidth]);
  return (
    <>
      <Navbar changePage={changePage} page={page} />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: below900 ? "column" : "row",

          // left: below900 ? undefined : "18%",
          paddingTop: 30,
          backgroundColor: "#28637d",
          minHeight: "100vh",
          // paddingLeft: 10,
        }}
      >
        {/* {!below900 && (
          <div
            style={{
              width: "18%",
              height: below900 ? 80 : "80vh",
              backgroundColor: "white",
              margin: 6,
              top: 60,
              left: 0,
              zIndex: 1,
              position: "fixed",
            }}
          >
            <AddBox />
          </div>
        )} */}
        {page == "React" && (
          <div style={{ width: below900 ? "95%" : "64%" }}>
            <ErrorBoundary>
              <Suspense>
                <ReactQuestionCard />
              </Suspense>
            </ErrorBoundary>
          </div>
        )}
        {page == "Javascript" && (
          <div style={{ width: below900 ? "95%" : "64%" }}>
            <ErrorBoundary>
              <Suspense>
                <JavascriptQuestionCard />
              </Suspense>
            </ErrorBoundary>
          </div>
        )}
        {page == "Express" && <Update />}
        {page == "MongoDB" && (
          <div style={{ width: below900 ? "95%" : "64%" }}>
            <ErrorBoundary>
              <MongodbQuestionCard />
            </ErrorBoundary>
          </div>
        )}
        {page == "Mysql" && <Update />}
        {/* <div
          style={{
            width: below900 ? "90%" : "18%",
            height: below900 ? 80 : "80vh",
            backgroundColor: "white",
            margin: 6,
            top: below900 ? undefined : 60,
            bottom: below900 ? 0 : undefined,
            right: below900 ? undefined : 0,
            zIndex: 1,
            position: "fixed",
            border: "1px solid black",
          }}
        >
          <AddBoxTwo below900={below900} />
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "gray",
        }}
      >
        <h6>Copyright © {currentYear} Ekveera </h6>
      </div>
    </>
  );
}

export default App;
