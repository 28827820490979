import Skeleton from "@mui/material/Skeleton";
export default function Loading() {
  return (
    <>
      <div
        style={{
          textAlign: "center",
          justifySelf: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
        <br />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={100}
        />
      </div>
    </>
  );
}
