import "../components/ErrorDiv.css";
function ErrorDiv({ message }) {
  return (
    <div class="error-container">
      <div class="error-icon">
        <i class="fas fa-times-circle"></i>
      </div>
      <div class="error-message">Something went wrong. Please check again.</div>
      <div>{message}</div>
    </div>
  );
}
export default ErrorDiv;
