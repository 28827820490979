import Button from "@mui/material/Button";
import { useState } from "react";
export function Navbar({ changePage, page }) {
  const buttonList = ["React", "Javascript", "Express", "MongoDB", "Mysql"];
  const [isSelected, setIsSelected] = useState(false);
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          overflow: "auto ",
          backgroundColor: "gray",
        }}
      >
        {buttonList.map((item, index) => {
          return (
            <Button
              key={index}
              variant="contained"
              style={{
                minWidth: 150,
                maxWidth: 150,
                maxHeight: 40,
                minHeight: 40,
                margin: 10,
                backgroundColor: item == page ? "#0e2336" : undefined,
              }}
              onClick={() => {
                changePage(item);
              }}
            >
              {item}
            </Button>
          );
        })}
      </div>
    </>
  );
}
