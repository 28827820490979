import "../components/Update.css";
function Update() {
  return (
    <div class="update-container">
      <div class="update-icon">
        <i class="fas fa-cloud-upload-alt"></i>
      </div>
      <div class="update-message">
        Our team is preparing to upload new data. Stay tuned for the latest
        updates!
      </div>
    </div>
  );
}

export default Update;
