import React, { memo, useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { Code, CodeBlock, dracula } from "react-code-blocks";
import { supabase } from "../api/Firebase";
import Button from "@mui/material/Button";
import Loading from "./Loading";
import Pagination from "@mui/material/Pagination";
import ErrorDiv from "./ErrorDiv";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import "../App.css";
function MongodbQuestionCard() {
  const [showAnswer, setShowAnswer] = useState(0);
  const [question, setQuestion] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const handlePageChange = (event, page) => {
    setPageNo(page);
  };
  const springProps = useSpring({
    height: showAnswer ? "auto" : 0,
    opacity: showAnswer ? 1 : 0,
  });
  const handleShowAnswer = (index) => {
    if (showAnswer == index) {
      setShowAnswer(0);
    } else {
      setShowAnswer(index);
    }
  };
  useEffect(() => {
    const fetchQuestion = async () => {
      const { data, error } = await supabase.from("mongodb").select("*");
      if (data) {
        setFetchError(null);
        setQuestion(data);
      }
      if (error) {
        setFetchError(error);
        setQuestion(null);
      }
    };
    fetchQuestion();
  }, [question, fetchError]);
  if (question == null && fetchError == null) {
    return <Loading />;
  }
  if (fetchError) {
    return (
      <div>
        <ErrorDiv />
      </div>
    );
  }
  if (question) {
    return (
      <div>
        {question.map((item, index) => {
          return (
            <Accordion
              key={item.id}
              className="question-card"
              onChange={() => {
                handleShowAnswer(item.id);
              }}
              expanded={showAnswer == item.id ? true : false}
            >
              <AccordionSummary
                expandIcon={
                  <ArrowCircleDownOutlinedIcon
                    style={{ color: "green", fontSize: 30 }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div>
                  <Typography>
                    {index + 1}. {item.question}
                  </Typography>

                  {item.questioncode && (
                    <CodeBlock
                      text={` ${item.questioncode}`}
                      language="js" // Specify the code language
                      showLineNumbers={true}
                      theme={dracula}
                      wraplines
                    ></CodeBlock>
                  )}
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <h4>Answer</h4>
                <pre
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: 18,
                    whiteSpace: "pre-wrap",
                    border: "2px solid green",
                    padding: 6,
                    borderRadius: 6,
                  }}
                  dangerouslySetInnerHTML={{ __html: item.answer }}
                ></pre>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  }
}
export default MongodbQuestionCard;
